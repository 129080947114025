.shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-family: "Kanit", sans-serif;
  font-weight: bold;
}

body {
  background-image: url("../img/whitebgfinal.png");
  /* background-size: cover;
  background-position: center; */
  min-height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

/* .leaves-img {
  position: absolute;
  z-index: -999999;
  height: 100vh;
} */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(1, 0, 0, 0.05);
  padding: 50px 50px 50px 50px;
  margin-top: 175px;
}

.container-1 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(53, 117, 68, 0.98);
  height: 550px;
  margin: 50px 100px 0 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 2%;
}

.container-2 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(53, 117, 68, 0.98);
  height: 650px;
  margin: 50px 100px 0 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 2%;
}

.dropdown-menu-scroll {
  height: 150px;
  overflow-y: scroll;
}

.dropdown-item {
  color: black;
}

.flex-text {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
}

.flex-top-row {
  display: flex;
}

.flex-dropdowns {
  display: flex;
  flex-direction: row;
}

.newNav {
  display: flex;
  justify-content: space-between;
  background-color: #54915e;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
}

.everpayLogo {
  width: 150px;
  height: 75px;
  margin-left: 10px;
}

.no-underline {
  text-decoration: none;
}

.nav-account {
  color: black;
  padding: 2px 15px 7px 38px;
  background-color: #416346;
  border-radius: 20px;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.75);
  text-decoration: none;
  margin-right: 10px;
  font-size: 14px;
}

.request-funds-button {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
  background-color: #92a374;
  border: rgba(0, 0, 0, 0.5) 3px solid;
  margin-top: 15px;
}

.request-funds-button:hover {
  top: -10px;
  background-color: black;
  text-decoration: none;
}

.nav-account:hover {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  cursor: pointer;
}

.stream-button {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
  background-color: #92a374;
  border: black 3px solid;
}

.stream-button-active {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
  background-color: #92a374;
  border: black 3px solid;
}

.stream-button-active:hover {
  top: -10px;
  background-color: black;
  text-decoration: none;
}

.stream-create-button {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
  background-color: #92a374;
  border: black 3px solid;
  display: none;
}

.stream-create-button:hover {
  top: -10px;
  background-color: black;
  text-decoration: none;
}

.stream-button:hover {
  top: -10px;
  background-color: black;
  text-decoration: none;
}

.dropdown-buttons {
  width: 100%;
  text-align: left;
  background-color: white;
  border: 1px solid rgb(224, 224, 224);
  box-shadow: 2px 9px 5px 0px rgba(0, 0, 0, 0.11);
  margin-top: -5px;
  margin-bottom: 5px;
  padding: -5px;
}

.tether-option {
  width: 100%;
}

.tether-option:hover {
  background-color: rgb(225, 225, 225);
}

.bar {
  padding: 2px 50px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 25px;
}

.bar-activestream {
  padding: 2px 40px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 25px;
  margin-bottom: 15px;
}

.modal-title {
  text-align: center;
  background-color: rgb(241, 241, 241);
}

.modal-footer {
  background-color: rgb(241, 241, 241);
}

.modal-body {
  display: flex;
  flex-direction: column;
  background-color: rgb(241, 241, 241);
}

.stream-amount {
  width: 100%;
  background-color: white;
  border: 1px solid rgb(224, 224, 224);
  box-shadow: 2px 9px 5px 0px rgba(0, 0, 0, 0.11);
  padding: 10px;
  margin-top: -5px;
  margin-bottom: 5px;
}

.final-time {
  width: 100%;
  background-color: white;
  border: 1px solid rgb(224, 224, 224);
  box-shadow: 2px 9px 5px 0px rgba(0, 0, 0, 0.11);
  text-align: center;
}

.dropdown-nav {
  width: 100%;
  background-color: white;
  border: 1px solid rgb(224, 224, 224);
  box-shadow: 2px 9px 5px 0px rgba(0, 0, 0, 0.11);
  margin-top: -5px;
  margin-bottom: 5px;
}

.funds-text {
  margin-top: 140px;
  color: rgba(0, 0, 0, 0.5);
}

.neg-top {
  margin-top: -10px;
}

.stream-text {
  margin-top: 170px;
}

.nav-right {
  display: flex;
  list-style: none;
  margin-top: 25px;
}

.nav-tether {
  margin-right: 15px;
}

.nav-wallet {
  margin-right: 10px;
}

.sent-text {
  /* border: 3px solid black; */
  margin-right: 10px;
  background-color: #416346;
  border: 1px solid #54805a;
  box-shadow: 2px 9px 5px 0px rgba(0, 0, 0, 0.21);
  padding: 5px;
  border-radius: 5%;
}

.sent-icon {
  margin-right: 5px;
}

.clock-icon {
  margin-right: 5px;
}

.withdraw-text {
  margin-right: 10px;
  background-color: #416346;
  border: 1px solid #54805a;
  box-shadow: 2px 9px 5px 0px rgba(0, 0, 0, 0.21);
  padding: 5px;
  border-radius: 5%;
}

.time-text {
  margin-right: 10px;
  background-color: #416346;
  border: 1px solid #54805a;
  box-shadow: 2px 9px 5px 0px rgba(0, 0, 0, 0.21);
  padding: 5px;
  border-radius: 5%;
  margin-right: 30px;
}

.show-approve-button {
  display: block;
}

.stream-btns {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.stream-approve {
  margin-right: 15px;
}

.progress-barr {
  margin-top: 15px;
}

.question-img:hover {
  cursor: pointer;
  opacity: 0.3;
}

.down-arrow {
  display: none;
}

.down-arrow-last {
  display: none;
}

.nav-img {
  height: 40px;
  width: 40px;
}

.nav-img-account {
  height: 40px;
  width: 40px;
}

@media (min-width: 1000px) {
  .stream-modal {
    position: fixed;
    top: -30px;
    right: 0px;
    transform: translateX(100%);
    animation: slide-in-right 0.5s forwards;
  }

  @keyframes slide-in-right {
    /* Start from the right side of the screen */
    from {
      transform: translateX(100%);
    }

    /* End at the final position */
    to {
      transform: translateX(0);
    }
  }
}

@media (max-width: 1000px) {
  .nav-account {
    font-size: 12px;
  }

  .container-1 {
    margin: 20px;
  }

  .container-2 {
    margin: 20px;
  }

  .modal-body {
    align-items: center;
    justify-content: center;
  }
  .stream-amount {
    width: 100%;
  }

  .navAccount {
    font-size: 11px;
    margin-top: 5px;
  }

  .nav-img {
    height: 40px;
    width: 40px;
  }

  .nav-img-account {
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 590px) {
  .down-arrow {
    display: block;
    padding: -0px 15px 0px 38px;
    background-color: #416346;
    border-radius: 20px;
    /* border: 2px rgba(0, 0, 0, 0.3) solid; */
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.75);
    font-weight: bold;
    margin-right: 10px;
    text-decoration: none;
  }

  .down-arrow-last {
    display: block;
    padding: 0px 15px 0px 38px;
    background-color: #416346;
    border-radius: 20px;
    /* border: 2px rgba(0, 0, 0, 0.3) solid; */
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.75);
    font-weight: bold;
    margin-right: 10px;
    text-decoration: none;
  }

  .nav-account {
    margin-right: 0px;
    display: none;
  }

  .nav-img {
    height: 32px;
    width: 32px;
  }

  .nav-img-account {
    height: 32px;
    width: 32px;
  }

  .navAccount {
    font-size: 11px;
    margin-top: 5px;
  }
}

@media (max-width: 420px) {
  .container-2 {
    margin: 20px;
    height: 620px;
  }

  .everpayLogo {
    width: 80px;
    height: 40px;
    margin-top: 10px;
  }

  .navAccount {
    font-size: 11px;
    margin-top: 5px;
  }

  .nav-account {
    color: black;
    font-size: 11px;
    padding: 3px;
    background-color: #416346;
    border-radius: 20%;
    border: 2px rgba(0, 0, 0, 0.3) solid;
    text-decoration: none;
    margin-right: 10px;
  }

  .stream-text {
    font-size: 14px;
  }

  .funds-text {
    font-size: 14px;
  }

  .stream-button {
    font-size: 14px;
  }

  .request-funds-button {
    font-size: 14px;
  }

  .sent-text {
    font-size: 11px;
  }

  .withdraw-text {
    font-size: 11px;
  }

  .time-text {
    font-size: 11px;
    margin-right: 20px;
  }

  .flex-text {
    margin-left: 20px;
  }

  .down-arrow {
    display: block;
    padding: 5px 15px 5px 38px;
    background-color: #416346;
    border-radius: 20px;
    /* border: 2px rgba(0, 0, 0, 0.3) solid; */
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.75);
    font-weight: bold;
    margin-right: 10px;
    text-decoration: none;
  }

  .down-arrow-last {
    display: block;
    padding: 5px 15px 5px 38px;
    background-color: #416346;
    border-radius: 20px;
    /* border: 2px rgba(0, 0, 0, 0.3) solid; */
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.75);
    font-weight: bold;
    text-decoration: none;
  }

  .nav-account {
    margin-right: 0px;
    display: none;
  }

  .flex-li {
    display: flex;
  }
}

@media (max-width: 376px) {
  .funds-text {
    margin-top: 140px;
  }

  .modal-body {
    /* margin: 0px; */
    padding: 0px;
  }

  .dropdown-nav {
    margin-bottom: 0px;
  }
}
